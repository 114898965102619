import { Component } from 'react';
import style from './index.module.scss';

class Error extends Component {
  render() {
    return (
      <div>
        Error
      </div>
    )
  }
}

export default Error;
